
//Data:
function data() {
  return {
    zoom: 100,
  }
}

//Meodos:
function plusZoom() {
  if(this.zoom < 100) {
    this.zoom += 10;
    return;
  }

  this.zoom += 30;
}
function minusZoom() {

  this.zoom -= 10;
}
function resetZoom() {
  this.zoom = 100;
}

//Computeds
function isOgZoom() {
  return this.zoom == 100;
}
function isMinimumZoom() {
  return this.zoom == 50;
}
function isLessThanOgZoom() {
  return this.zoom < 100;
}

export default {
  data,
  methods: {
    plusZoom,
    minusZoom,
    resetZoom,
  },
  computed: {
    isOgZoom,
    isMinimumZoom,
    isLessThanOgZoom,
  }
}