<template>
  <div class="planificacion-body" v-if="showLayout">
    <preferences-modal
      :modal.sync="modalPref"
      :preferencias="preferencias"
      :selecteds="currentPreferences"
      :filter="filtro"
      :port="puerto"
      :modulo="modulo"
      :visitEdiFileId="!visitInfo ? '' : visitInfo.EdiFileId"
      @submit="handleSelecteds"
    />
    <leyenda-modal
      :leyenda="currentLegends"
      :modal.sync="legendModal"
      :modulo="modulo"
      :preferencias="currentPreferences"
    />
    <imprimir-modal
      :viewsInfo="viewsInfo"
      :extraInfo="extraInfo"
      :modal.sync="imprimirModal"
    />
    <matching-modal
      :modal.sync="matchingModal"
      :matchInfo="currentMatching"
      :modulo="modulo"
      :visitEdiFileId="!visitInfo ? '' : visitInfo.EdiFileId"
      @generated="handleMatching"
    />
    <div class="wrapper d-flex align-items-stretch">
      <nav id="detailSidebar">
        <div class="pb-4 pt-2 sidebar-inner-space">
          <ul class="list-unstyled components mb-4 mx-4">
            <li class="text-center align-middle mb-4">
              <span class="view-title text-wrap ">{{ currentView }}</span>
            </li>
            <li class="mb-1">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                @click.stop="toggleLegend"
              >
                <div v-if="!isLegendLoading">
                  <CIcon name="cil-eye" />
                  <span class="ml-1">{{$t('label.legend')}}</span>
                </div>
                <div v-else>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="ml-1">{{$t('label.legend')}}</span>
                </div>
              </CButton>
            </li>
            <li class="mb-1">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled="isPreferenciasEmpty"
                @click.stop="togglePref"
              >
                <div v-if="!isLoadingFiltros">
                  <CIcon name="cil-filter" />
                  <span class="ml-1">{{$t('label.preferences')}}</span>
                </div>
                <div v-else>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="ml-1">{{$t('label.preferences')}}</span>
                </div>
              </CButton>
            </li>
            <li class="mb-1">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled="isLegendLoading || isLoadingFiltros"
                @click.stop="printPage()"
              >
                <CIcon name="cil-print" />
                <span class="ml-1">{{$t('label.print')}}</span>
              </CButton>
            </li>
            <li class="mb-4">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                @click.stop="refreshBaysList"
                v-if="modulo == 'visit'"
              >
                <CIcon name="cil-reload"/>
                <span class="ml-1">{{$t('label.refresh')}}</span>
              </CButton>
            </li>
            <li>
              <CSelect
                size="sm"
                :label="$t('label.columns')"
                placeholder="Cols"
                addLabelClasses="text-dark"
                :horizontal="{ label: 'col-sm-6', input: 'col-sm-6'}"
                :options="colSelect"
                :value.sync="columnas"
              />
            </li>
            <li>
              <CSelect
                size="sm"
                :label="$t('label.rows')"
                placeholder="FILAS"
                addLabelClasses="text-dark"
                :horizontal="{ label: 'col-sm-6', input: 'col-sm-6'}"
                :disabled="isFilasEmpty"
                :options="filasOptions"
                :value.sync="filas"
              />
            </li>
          </ul>
          <ul class="list-unstyled components">
            <li>
              <div class="filter-collapse" @click.stop="toggleMultiple">
                <span class="ml-1">{{$t('label.multipleFilter')}}</span>
                <CIcon
                  name="cil-chevron-bottom"
                  :class="{
                    'ml-3': true,
                    'arrow-icon': true,
                    'rotate': multiple,
                  }"
                />
              </div>
              <CCollapse :show="multiple">
                <div class="mx-4 my-2">
                  <CButton
                    block
                    size="sm"
                    color="secondary"
                    class="d-flex align-items-center justify-content-center"
                    @click.stop="toggleMatching"
                  >
                    <CIcon name="cil-filter" />
                    <span class="ml-1">{{$t('label.matching')}}</span>
                  </CButton>
                </div>
              </CCollapse>
            </li>
            <li>
              <div class="filter-collapse" @click.stop="toggleNormales">
                <span class="ml-1">{{$t('label.generalFilter')}}</span>
                <CIcon
                  name="cil-chevron-bottom"
                  :class="{
                    'ml-3': true,
                    'arrow-icon': true,
                    'rotate': normales,
                  }"
                />
              </div>
              <CCollapse :show="normales">
                <div class="mx-4 my-2">
                  <div class="selects-container-general mb-2">
                    <span>{{$t('label.filterBy')}}</span>
                    <div class="position-relative">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.selectFilter')"
                        class="no-margin-select ml-2"
                        :options="filtrosOptions"
                        :disabled="isFiltrosEmpty"
                        :value.sync="filtro"
                      />
                      <loading v-show="isLoadingFiltros" element="select"/>
                    </div>
                  </div>
                  <div class="selects-container-general mb-2">
                    <span>{{$t('label.port')}}</span>
                    <div class="position-relative">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        class="no-margin-select ml-2"
                        :options="puertosOptions"
                        :disabled="isPuertosEmpty"
                        :value.sync="puerto"
                      />
                      <loading v-show="isLoadingPuertos" element="select"/>
                    </div>
                  </div>
                  <div class="selects-container-general">
                    <span>{{$t('label.applyPlan')}}</span>
                    <div class="position-relative d-flex justify-content-center">
                      <CSwitch
                        color="primary"
                        :checked="false"
                        size="lg"
                        :disabled="puerto == ''"
                        @update:checked="aplicaPlano"
                      />
                    </div>
                  </div>
                </div>
              </CCollapse>
            </li>
          </ul>
        </div>
      </nav>

      <!-- Page Content  -->
      <div id="content">
        <!-- Navbar -->
        <CNavbar
          expandable="lg"
          light
          class="menu-view-cargo no-margin"
          color="secondary"
        >
          <div class="sidebar-controls">
            <CButton
              color="secondary"
              shape="square"
              class="mr-2"
              size="sm"
              @click.stop="toggleSidebar"
            >
              <CIcon name="cil-menu" />
              <span class="sr-only">Toggle Menu</span>
            </CButton>
            <CButton
              color="primary"
              shape="square"
              size="sm"
              :disabled="isMinimumZoom"
              @click.stop="minusZoom"
            >
              <CIcon name="cil-zoom-out" />
            </CButton>
            <a
              href="#"
              class="zoom-number"
              @click.prevent="resetZoom"
            >
              {{ `${zoom}%` }}
            </a>
            <CButton
              color="primary"
              shape="square"
              size="sm"
              @click.stop="plusZoom"
            >
              <CIcon name="cil-zoom-in" />
            </CButton>
          </div>
          <CToggler in-navbar @click="collapsed = !collapsed" />
          <CCollapse :show="collapsed" navbar>
            <CNavbarNav>
              <div class="mr-3 ml-2 selects-container">
                <span>{{ `${getTpEdiName}:` }}</span>
                <CInput
                  size="sm"
                  :disabled="true"
                  :value.sync="getEdiName"
                  class="no-margin-select ml-2"
                  v-if="modulo == 'stowage'"
                >
                </CInput>
              </div>
              <div class="position-relative selects-container">
                <span>{{$t('label.viewType')}}</span>
                <CSelect
                  size="sm"
                  :placeholder="$t('label.select')"
                  class="no-margin-select ml-2"
                  :options="tpVistasOptions"
                  :disabled="isVistasEmpty"
                  :value.sync="vista"
                />
                <loading v-show="isLoadingVistas" element="select"/>
              </div>
            </CNavbarNav>
            <CNavbarNav class="ml-auto"> </CNavbarNav>
          </CCollapse>
          <div class="btn-pagination">
            <span>{{$t('label.page')}} {{ currentPage }} {{$t('label.of')}} {{ numberOfPages}}</span>
            <CButtonGroup class="ml-2">
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.first')} ${$t('label.page')}`,
                  placement: 'top-end',
                }"
                :disabled="leftBtnsDisabled"
                @click.stop="firstPage"
              >
                <CIcon name="cil-chevron-double-left" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.previous'),
                  placement: 'top-end',
                }"
                :disabled="leftBtnsDisabled"
                @click.stop="previousPage"
              >
                <CIcon name="cil-arrow-thick-to-left" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.next'),
                  placement: 'top-end',
                }"
                :disabled="rightBtnsDisabled"
                @click.stop="nextPage"
              >
                <CIcon name="cil-arrow-thick-to-right" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.last')} ${$t('label.page')}`,
                  placement: 'top-end',
                }"
                :disabled="rightBtnsDisabled"
                @click.stop="lastPage"
              >
                <CIcon name="cil-chevron-double-right" />
              </CButton>
            </CButtonGroup>
          </div>
        </CNavbar>
        <!-- Contenido Interno -->
        <div class="container-fluid">
          <CRow class="mt-5 mb-3">
            <CCol sm="12" ref="content">
              <div 
                class="zoom-container"
                :class="{ 
                  'grab-bing': !isOgZoom ? true:null,
                  'zoom-center': isLessThanOgZoom ? true:null,
                }"
                v-dragscroll="!isOgZoom"
              >
                <div class="bay-container" :style="{ width: `${zoom}%` }">
                  <div
                    class="bay-row"
                    :style="{ width: `calc( calc(100% / ${columnas}) - 10px )` }"
                    v-for="(bays, index) in pageList"
                    :key="index"
                  >
                    <div class="bay-col" v-for="(bay, index) in bays" :key="index">
                      <bay-container
                        v-if="bay != null"
                        obligatory
                        showStatusView
                        :margin="0.5"
                        :bayInfo="bay"
                        :maxSize="zoom"
                        :matching="getFilterMatching"
                        :positionsToDeck="getPositionsToDeck(bay)"
                        :positionsUnderDeck="getPositionsUnderDeck(bay)"
                        :colSize="generalCol"
                        :filterPort="filterByPort"
                        :preferences="currentPreferences"
                        :viewElement="viewElement"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BayContainer from "@/pages/planificacion-estiba/archivos/bays/bay-container";
import PreferencesModal from "./preferences-modal";
import ImprimirModal from "./imprimir-modal";
import LeyendaModal from "./leyenda-modal";
import Pagination from '@/_mixins/PaginateMixin';
import BayMixin from "@/_mixins/bays";
import Zoom from '@/_mixins/ZoomMixin';
import { dragscroll } from 'vue-dragscroll';
import { DateFormater } from '@/_helpers/funciones';
import MatchingModal from "./matching-modal";

// Data
function data() {
  return {
    isLoading: false,
    items: [],
    showLayout: false,
    collapsed: false,
    generalCol: 0,
    //Usadas para los filtros de preferencias.
    vistas: [],
    vista: "1E9A8EC6-4EA0-479A-B563-8210B1DCAC50",
    isLoadingVistas: false,
    filtros: [],
    filtro: "",
    isLoadingFiltros: false,
    puertos: [],
    puerto: "",
    isLoadingPuertos: false,
    //Preferencias para el modal.
    preferencias: [],
    modalPref: false,
    selectedOptions: [],
    viewElement: "",
    //Pagination && Quantity
    colSelect: [],
    columnas: 8,
    filas: 1,
    // Leyenda
    legendModal: false,
    viewsInfo: [],
    isLegendLoading: false,
    //Imprimir
    imprimirModal: false,
    //Filtros
    multiple: false,
    normales: false,
    // matching
    matchingModal: false,
    // Visita
    visitInfo : null,
    visitItinerary: null,
    //Aplica Plano
    apply: false,
  };
}

// created
function created() {
  this.colSelect = this.generateSelect(1, 8);
}

// methods:
function getBaysCargoInfo() {
  if(this.modulo == 'stowage')
    this.$store.state.planificacionestiba.apiStateForm = 1;
  if(this.modulo == 'visit')
    this.$store.state.visitas.apiStateForm = 1;

  let ruta = this.modulo == 'stowage'
    ? 'StowagePlanningVesselBayCargo-list':'VisitVesselBayCargo-list';

  let parametros = this.modulo == 'stowage'
    ? {
      StowagePlanningId: this.planificacionId,
      EdiFileId: this.ediFileId,
    } 
    : {
      VisitId: this.visitId,
      EdiFileId: this.visitInfo.EdiFileId,
    };
    

  this.$http.ejecutar("GET", ruta, parametros)
    .then((response) => {
      this.items = response.data.data;
      this.generalCol = this.generalResponsiveCol(this.items);
      this.columnas = 8;
      this.filas = 2;
      this.numberPerPage = this.columnas * this.filas;
      this.showLayout = true;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      if(this.modulo == 'stowage')
        this.$store.state.planificacionestiba.apiStateForm = 0;
      if(this.modulo == 'visit')
        this.$store.state.visitas.apiStateForm = 0;
    });
}
function getTpVistas() {
  this.isLoadingVistas = true;

  let parametros = this.modulo == 'stowage'
    ? {
      TpEdiId: this.ediInfo ? this.ediInfo.TpEdiId : "",
    }
    : {
      TpEdiId: "010f2d7c-e725-ec11-a874-000d3a0dedc7",
    } 

  this.$http
    .ejecutar("GET", "EdiView-list", parametros)
    .then((response) => {
      this.vistas = response.data.data;
      //EdiViewId = 
      if(this.modulo == 'stowage'){
        if(this.ediInfo.TpEdiId == '74423312-1450-4B16-B837-D5620CDCDD1D')
          this.vista = '14D86473-A068-49C9-AD2A-2DFF5C654217';
        else {
          this.vista = '1E9A8EC6-4EA0-479A-B563-8210B1DCAC50';
        }
      }
      if(this.modulo == 'visit')
        this.vista = '1E9A8EC6-4EA0-479A-B563-8210B1DCAC50';

      this.addSelectedView(this.vista);
      let viewAux = this.vistas.find((item) => item.EdiViewId === this.vista);
      this.viewElement = viewAux?.Element ? viewAux.Element : "";
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingVistas = false;
    });
}
function getFiltros() {
  this.isLoadingFiltros = true;

  this.$http
    .ejecutar("GET", "EdiViewFilter-list", {
      EdiViewId: this.vista,
    })
    .then((response) => {
      this.filtros = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingFiltros = false;
    });
}
function getPuertos(ServiceUrl = "") {
  if (ServiceUrl == null || ServiceUrl == "") return;

  let parametros = this.modulo == 'stowage'
    ? {
      StowagePlanningId: this.planificacionId,
      StowagePlanningEdiId: this.ediFileId,
    }
    : {
      VisitId: this.visitId,
      VisitEdiFileId: this.visitInfo.EdiFileId,
    } 

  this.isLoadingPuertos = true;
  this.$http.ejecutar("GET", ServiceUrl, parametros)
    .then((response) => {
      this.puertos = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingPuertos = false;
    });
}
function getPreferencias() {
  this.$http
    .ejecutar("GET", "EdiViewPreference-list", {
      EdiViewId: this.vista,
    })
    .then((response) => {
      this.preferencias = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    });
}
function togglePref() {
  this.modalPref = true;
}
function toggleLegend() {
  this.legendModal = true;
}
function resetData() {
  this.generalCol = 0;
  this.columnas = 8;
  this.filas = 1;
  this.resetPagination();
  //Usadas para los filtros de preferencias.
  this.vistas = [];
  this.vista = "1E9A8EC6-4EA0-479A-B563-8210B1DCAC50";
  this.filtros = [];
  this.filtro = "";
  this.puertos = [];
  this.puerto = "";
  //Preferencias para el modal.
  this.preferencias = [];
  this.selectedOptions = [];
  this.viewElement = "";
  //Zoom
  this.resetZoom();
  //Info de Leyenda y preferencias:
  this.viewsInfo = [];
  // Filtros multiples
  this.multiple = false;
  this.normales = false;
  // Visita
  this.visitaInfo = null;
  this.visitItinerary = null;
  this.apply = false;
}
function handleSelecteds(options) {
  let index = this.viewsInfo.findIndex((item) => item.EdiViewId === this.vista);
  this.viewsInfo[index].preferences = [...options];
}
function toggleSidebar() {
  document.querySelector("#detailSidebar").classList.toggle("active");
}
function generateSelect(init, end, pares = false) {
  let select = [];

  if(pares) {
    for (let index = init; index <= end; index++) {
      if(index%2 == 0){
        select.push({
          label: index < 10 ? `0${index}`:`${index}`,
          value: index,
        });
      }
    }
  } else {
    for (let index = init; index <= end; index++) {
      select.push({
        label: index < 10 ? `0${index}`:`${index}`,
        value: index
      });
    }
  }

  return select;
}

function addSelectedView(viewId) {
  let view = this.viewsInfo.find((item) => item.EdiViewId === viewId);
  if(view || viewId == '')
    return;

  let addView = this.vistas.find((item) => item.EdiViewId === viewId);
  if(!addView)
    return;
    
  this.viewsInfo.push({
    ...addView,
    legends: [],
    preferences: [],
    filter: {},
    matching: null,
  });
  this.getLegends(viewId);
}
function getLegends(viewId) {
  this.isLegendLoading = true;

  let whiteList = [
    "878E5685-0BC1-43E1-9490-F3A1F2645630",
    "6448779A-474A-4BDA-834B-7F069375FDA8"
  ];

  let ruta = this.modulo == 'stowage'
    ? 'EdiMandatory-list':'VisitEdiMandatory-list';

  let parametros = this.modulo == 'stowage'
    ? {
      StowagePlanningId: this.planificacionId,
      EdiFileId: this.ediFileId,
      VesselBayId: '',
      EdiViewId: whiteList.includes(viewId) ? viewId : '',
    } 
    : {
      VisitId: this.visitId,
      VisitEdiFileId: this.visitInfo.EdiFileId,
      VesselBayId: '',
      EdiViewId: whiteList.includes(viewId) ? viewId : '',
    };

  this.$http.ejecutar("GET", ruta, parametros)
    .then((response) => {
      let index = this.viewsInfo.findIndex((item) => item.EdiViewId === viewId);
      if(index != -1)
        this.viewsInfo[index].legends = [...response.data.data];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLegendLoading = false;
    });
}

function printPage() {
  this.imprimirModal = true;
}
function extraInfo() {
  let limitFilas =  this.filas > 2 ? 2 : this.filas;
  
  return {
    baysList: this.formatedBayList,
    itineraryInfo: this.currentItinerary,
    columnas: this.columnas,
    generalCol: this.generalCol,
    numberPerPage: this.columnas * limitFilas,
    unformatedList: this.filterItems,
    modulo: this.modulo,
  };
}
function toggleMultiple() {
  this.multiple = !this.multiple;
}
function toggleNormales() {
  this.normales = !this.normales;
}
function toggleMatching() {
  this.matchingModal = true;
}
function handleMatching(match) {
  let index = this.viewsInfo.findIndex((item) => item.EdiViewId === this.vista);
  this.viewsInfo[index].matching = match ? {...match} : null;
  this.filtro = ''
  this.puerto = '';
  this.apply = false;
}

function getVisitInfo() {
  this.$http
    .ejecutar("GET", "VisitEdiFile-list", {
      VisitId: this.visitId,
    })
    .then((response) => {
      if(response.data.data.length != 0) {
        this.visitInfo = {...response.data.data[0]};
        this.getTpVistas();
        this.getBaysCargoInfo();
        this.getFiltros();
        this.getPreferencias();
        this.getVisitItinerary();
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    });
}
function getVisitItinerary() {
  this.$http
    .ejecutar("GET", "Visit-by-VisitId", {
      VisitId: this.visitId,
    })
    .then((response) => {
      this.visitItinerary = {...response.data.data[0]};
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    });
}

function aplicaPlano(val, e){
  if(val)
    this.filas = 1;
  else
    this.filas = 2;
  this.apply = val;
}
function refreshBaysList() {
  this.getBaysCargoInfo();
}

//Computadas:
function isVistasEmpty() {
  return this.vistas.length === 0;
}
function tpVistasOptions() {
  return this.vistas.map((item) =>
    Object.assign({}, item, {
      label: item.EdiViewName,
      value: item.EdiViewId,
    })
  );
}
function isFiltrosEmpty() {
  return this.filtros.length === 0;
}
function filtrosOptions() {
  return this.filtros.map((item) =>
    Object.assign({}, item, {
      label: item.EditPreferenceName,
      value: item.EditPreferenceId,
    })
  );
}
function isPuertosEmpty() {
  return this.puertos.length === 0;
}
function puertosOptions() {
  return this.puertos.map((item) =>
    Object.assign({}, item, {
      label: item.PortCode,
      value: item.PortId,
    })
  );
}
function isPreferenciasEmpty() {
  return this.preferencias.length === 0;
}
function filterByPort() {
  let filter = this.filtros.find(
    (item) => item.EditPreferenceId === this.filtro
  );
  let port = this.puertos.find((item) => item.PortId === this.puerto);

  return {
    FilterType: filter ? filter.EditPreferenceElement : "",
    PortId: port ? port.PortId : "",
    PortCode: port ? port.PortCode : "",
  };
}
function currentView() {
  let view = this.vistas.find((item) => item.EdiViewId == this.vista);
  return view?.EdiViewName || "";
}
function isFilasEmpty() {
  this.filasOptions.length == 0;
}
function filasOptions() {
  let quantity = Math.ceil(this.formatedBayList.length / this.columnas);
  return this.generateSelect(1, quantity);
}
function formatedBayList() {
  let assigned = [];
  let formated = [];
  let newItems = this.filterItems;

  if(newItems.length != 0){
    for (let index = 0; index < newItems.length; index++) {
      const bay = newItems[index];
      let asignado = assigned.findIndex((item) => item.VesselBayId === bay?.VesselBayId);
      if (asignado == -1) {
        let found = bay.VesselBayIdMirror
          ? newItems.find((item) => item.VesselBayId === bay?.VesselBayIdMirror)
          : null;
        formated.push([bay, found]);
        assigned.push(bay);
        if (found) assigned.push(found);
      }
    }
  }

  return formated;
}

function currentLegends() {
  let index = this.viewsInfo.findIndex((item) => item.EdiViewId === this.vista);
  return index != -1 ? this.viewsInfo[index].legends : [];
}
function currentPreferences() {
  let index = this.viewsInfo.findIndex((item) => item.EdiViewId === this.vista);
  return index != -1 ? this.viewsInfo[index].preferences : [];
}
function lengthFilterQty() {
  return this.filtros.length;
}
function currentMatching() {
  let index = this.viewsInfo.findIndex((item) => item.EdiViewId === this.vista);
  return index != -1 ? this.viewsInfo[index].matching : null;
}
function getFilterMatching() {
  let index = this.viewsInfo.findIndex((item) => item.EdiViewId === this.vista);
  return index != -1
    ? !this.viewsInfo[index].matching
      ? []
      : [...this.viewsInfo[index].matching.row, ...this.viewsInfo[index].matching.col]
    : [];
}

function filterItems(){
  if(!this.apply)
    return this.items;
  else{
    let filtro = this.filterByPort;

    return this.items.filter((item) => {
      if(!item.PositionJson) return false;
      for (let index = 0; index < item.PositionJson.length; index++) {
        if(item.PositionJson[index].CargoJson && item.PositionJson[index].CargoJson[0][filtro.FilterType] == filtro.PortId)
          return true
      }

      return false;
    });
  }
}

//Overwrite
function numberOfPages() {
  return Math.ceil(this.formatedBayList.length / this.numberPerPage);
}
function pageList() {
  var begin = ((this.currentPage - 1) * this.numberPerPage);
  var end = begin + this.numberPerPage;

  return this.formatedBayList.slice(begin, end);
}
function leftBtnsDisabled() {
  return this.currentPage == 1 || this.formatedBayList.length == 0;
}
function rightBtnsDisabled() {
  return this.currentPage == this.numberOfPages || this.formatedBayList.length == 0;
}
function currentItinerary() {
  if(this.modulo == 'stowage') {
    if(this.ItineraryId !== 0 || this.ItineraryId !== null){
      const Index = this.myDataItinirary.map(function(e) {
          return e.ItineraryId; 
      }).indexOf(this.ItineraryId);
      
      if(Index > -1){
        return {
          DatePlanning: this.myDataItinirary[Index].DatePlanning,
          TpVesselName: this.myDataItinirary[Index].TpVesselName,
          Voyage: this.myDataItinirary[Index].Voyage,
          Ata:  this.myDataItinirary[Index].Ata ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Ata) : 'N/A' ,
          Atd: this.myDataItinirary[Index].Atd ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Atd) : 'N/A' ,
          Eta:  this.myDataItinirary[Index].Eta ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta) : 'N/A' ,
          Etd: this.myDataItinirary[Index].Etd ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd) : 'N/A' ,
          VesselId: this.myDataItinirary[Index].VesselId,
          VesselName: this.myDataItinirary[Index].VesselName,
          VesselFlagRoute: this.myDataItinirary[Index].VesselFlagRoute,
          VesselRoute: `${this.myDataItinirary[Index].VesselRoute}`,
          ServiceCode: this.myDataItinirary[Index].ServiceCode,
          Imo: this.myDataItinirary[Index].Imo,
          Mmsi: this.myDataItinirary[Index].Mmsi,
          Proa: this.myDataItinirary[Index].Proa,
          Popa: this.myDataItinirary[Index].Popa,
          Teus: this.myDataItinirary[Index].Teus,
          VesselTeus: this.myDataItinirary[Index].VesselTeus,
          CountryName: this.myDataItinirary[Index].CountryName,
        }
      } else return null;
    }
  }
  if(this.modulo == 'visit') {
     if(this.VisitId !== '' && this.visitItinerary){
        return {
          Voyage: this.visitItinerary.VoyageArrival,
          LabelArrival: this.visitItinerary.LabelArrival,
          Arrival:  this.visitItinerary.Arrival ? DateFormater.formatDateTimeWithSlash(this.visitItinerary.Arrival) : 'N/A' ,
          LabelDeparture: this.visitItinerary.LabelDeparture,
          Departure:  this.visitItinerary.Departure ? DateFormater.formatDateTimeWithSlash(this.visitItinerary.Departure) : 'N/A' ,
          Ata:  this.visitItinerary.Ata ? DateFormater.formatDateTimeWithSlash(this.visitItinerary.Ata) : 'N/A' ,
          Atd: this.visitItinerary.Atd ? DateFormater.formatDateTimeWithSlash(this.visitItinerary.Atd) : 'N/A' ,
          VesselId: this.visitItinerary.VesselId,
          VesselName: this.visitItinerary.VesselName,
          Imo: this.visitItinerary.Imo,
        }
     } else return null;
  }
  return null;
}

function getTpEdiName() {
  if(this.modulo == 'stowage') {
    if(!this.ediInfo) return '';
  
    if(this.ediInfo.TpEdiId == 'AE4FB2C8-8471-43C8-9DE0-6394173F0330') {
      return this.ediInfo.TpEdiTransacName;
    } else {
      return this.ediInfo.TpEdiName;
    }
  } 
  if(this.modulo == 'visit') {
    if(!this.visitInfo) return '';

    return this.visitInfo.TpEdiName;
  }

  return '';
}
function getEdiName() {
  if(this.modulo == 'stowage') {
    if(!this.ediInfo) return '';
  
    return this.ediInfo.EdiFileName;
  } 
  if(this.modulo == 'visit') {
    if(!this.visitInfo) return '';
  
    return this.visitInfo.EdiFileName;
  } 

  return '';
}

export default {
  name: "full-cargo-view",
  components: {
    BayContainer,
    PreferencesModal,
    LeyendaModal,
    ImprimirModal,
    MatchingModal,
  },
  mixins: [Pagination, Zoom, BayMixin],
  props: {
    modulo: {
      type: String,
      default: 'stowage'
    }
  },
  data,
  directives: {
    dragscroll
  },
  created,
  methods: {
    getBaysCargoInfo,
    getTpVistas,
    getFiltros,
    getPuertos,
    getPreferencias,
    togglePref,
    resetData,
    handleSelecteds,
    toggleSidebar,
    generateSelect,
    toggleLegend,
    addSelectedView,
    getLegends,
    printPage,
    toggleMultiple,
    toggleNormales,
    toggleMatching,
    handleMatching,
    getVisitInfo,
    getVisitItinerary,
    aplicaPlano,
    refreshBaysList,
  },
  computed: {
    isVistasEmpty,
    isFiltrosEmpty,
    isPuertosEmpty,
    isPreferenciasEmpty,
    tpVistasOptions,
    filtrosOptions,
    puertosOptions,
    filterByPort,
    currentView,
    formatedBayList,
    isFilasEmpty,
    filasOptions,
    numberOfPages,
    pageList,
    leftBtnsDisabled,
    rightBtnsDisabled,
    currentPreferences,
    currentLegends,
    currentItinerary,
    lengthFilterQty,
    extraInfo,
    getTpEdiName,
    getEdiName,
    currentMatching,
    getFilterMatching,
    filterItems,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      ediFileId: (state) => state.planificacionestiba.EdiFileId,
      dropItem: (state) => state.planificacionestiba.dropItem,
      collapsePlanificacion: (state) =>
        state.planificacionestiba.collapsePlanificacion,
      isHidden: (state) => state.planificacionestiba.isHidden,
      ediInfo: (state) => state.planificacionestiba.EdiFileInfo,
      ItineraryId: state => state.planificacionestiba.ItineraryId,
      myDataItinirary: state => state.planificacionestiba.myDataItinirary,
      visitId: state => state.visitas.VisitId,
      dropItemElectronic: state => state.visitas.dropItemElectronic
    }),
  },
  watch: {
    dropItem: function (newItem) {
      if (newItem == 15) {
        this.getTpVistas();
        this.getBaysCargoInfo();
        this.getFiltros();
        this.getPreferencias();
      } else {
        this.showLayout = false;
        this.resetData();
      }
    },
    dropItemElectronic: function (newItem) {
      if(this.modulo == 'visit'){
        if (newItem == 1) {
          this.getVisitInfo();
        } else {
          this.showLayout = false;
          this.resetData();
        }
      }
    },
    vista: function (newId) {
      let erease = true;
      if (newId) {
        this.getFiltros();
        this.getPreferencias();
        this.addSelectedView(newId);
        let viewAux = this.vistas.find((item) => item.EdiViewId === newId);
        this.viewElement = viewAux && viewAux?.Element ? viewAux.Element : "";

        let index = this.viewsInfo.findIndex((item) => item.EdiViewId === newId);
        if(index != -1 && Object.keys(this.viewsInfo[index].filter).length != 0){
          this.filtro = this.viewsInfo[index].filter.filtro;
          this.puerto = this.viewsInfo[index].filter.puerto;
          erease = false;
        }
      }
      
      if(erease){
        this.filtro = "";
        this.puerto = "";
      }
      this.currentPage = 1;
      this.puertos = [];
      this.selectedOptions = [];
    },
    filtro: function (newId) {
      if (newId) {
        let filter = this.filtros.find(
          (item) => item.EditPreferenceId === newId
        );
        if(filter)
          this.getPuertos(filter.ServiceUrl);
      }

      let index = this.viewsInfo.findIndex((item) => item.EdiViewId === this.vista);
      if(index == -1 || Object.keys(this.viewsInfo[index].filter).length == 0){
        this.puerto = "";
      }
    },
    puerto: function (newId) {
      let index = this.viewsInfo.findIndex((item) => item.EdiViewId === this.vista);
      if (newId  && index != -1) {
        this.viewsInfo[index].filter = {
          ...this.filterByPort,
          filtro: this.filtro,
          puerto: newId,
        };
        this.viewsInfo[index].matching = null;
      } else {
        this.puertos = [];
      }
    },
    lengthFilterQty: function (cantidad) {
      if(cantidad != 0 && this.filtro) {
        let filter = this.filtros.find(
          (item) => item.EditPreferenceId === this.filtro
        );
        if(filter)
          this.getPuertos(filter.ServiceUrl);
      }
    },
    filas:  function(newValue) {
      if(newValue) {
        this.numberPerPage = this.columnas * this.filas;
      }
    },
    columnas:  function(newValue) {
      if(newValue) {
        if(newValue >= 6)
          this.filas = 2;
          
        this.numberPerPage = this.columnas * this.filas;
      }
    },
    collapsePlanificacion: function (newValue) {
      if (!newValue) {
        this.showLayout = false;
        this.resetData();
      }
    },
    isHidden: function (newValue) {
      if (!newValue) {
        this.showLayout = false;
        this.resetData();
      }
    },
  },
};
</script>

<style scoped>
/* Contenido de los Bays */
.bay-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  /* max-height: 773px; */
  overflow-y: hidden;
  overflow-x: hidden;
  /* scrollbar-width: thin; */
}

.bay-row {
  position: relative;
  margin: 0 0 5px 5px;
  width: calc(calc(100% / 3) - 10px);
  padding: 10px 0;

  display: flex;
  flex-direction: column;
}

.bay-col {
  position: relative;
  width: 100%;
  flex: 1;
}
.bay-col:first-child {
  margin-bottom: 15px;
}

/* Zoom */
.zoom-container {
  width: 100%;
  max-height: 773px;
  overflow: auto;
  /* scrollbar-width: thin; */
}
.zoom-center {
  display: flex;
  justify-content: center;
}
.sidebar-controls {
  display: flex;
  
  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}
.zoom-number {
  padding:5px;
  background-color: #d6d7db;
  border: 1px solid #636f83;
}
.zoom-number:hover {
  background-color: #636f83;
  color: #d8dbe0;
}
.grab-bing {
  cursor : -webkit-grab;
  cursor : -moz-grab;
  cursor : -o-grab;
  cursor : grab;
}
.grab-bing:active {
  cursor : -webkit-grabbing;
  cursor : -moz-grabbing;
  cursor : -o-grabbing;
  cursor : grabbing;
}

/* Sidebar */
.planificacion-body #detailSidebar {
  min-width: 200px !important;
  max-width: 200px !important;
  background-color: #e6e3e3 !important;
  background-image: none;
  border: 1px solid #000;
  transition: all 0.3s;
  z-index: 1;
}
.planificacion-body #content {
  min-height: 834px;
  max-height: 1024px;
  /* overflow-y: auto;
  scrollbar-width: thin; */
}
.sidebar-inner-space {
  /* margin-top: 2rem !important; */
  margin-top: 0;
}
.planificacion-body #detailSidebar.active {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 0; /* sólo en caso de elementos inline-block */
  overflow: hidden;
  margin-left: -200px;
}
.btn-sidebar-detail {
  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}

/* Navbar */
.menu-view-cargo.bg-secondary {
  background-color: #f5f7fa !important;
  border: 1px solid #636f83;
  border-top: none;
  box-shadow: 0 6px 6px -6px #000;
}
.menu-view-cargo.no-margin {
  box-shadow: none;
  /* margin-bottom: 0; */
}
.nav-internal-item {
  color: #8994a8 !important;
}
.nav-internal-item:hover {
  color: #556279 !important;
}
.nav-internal-dropdown {
  background-color: #f5f7fa !important;
}
.no-margin-select {
  margin-bottom: 0;
}
.selects-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-title {
  color: #3c4b64;
}

/* Pagination */
.btn-pagination {
  position: absolute;
  bottom: -32px;
  right: 0;
  z-index: 3;

  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

/* Filtros multiples */
.filter-collapse {
  width: 100%;
  cursor: pointer;
  color: #4e4c4d;
  background-color: #f8f6f7;
  border: 1px solid #abaaa8;
  border-left: none;
  border-right: none;

  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-icon {
  transition: transform 0.15s ease-in;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.arrow-icon.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.selects-container-general {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
</style>