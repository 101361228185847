<template>
  <CModalExtended
    :title="$t('label.matching')"
    color="dark"
    size="xl"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <CRow class="options">
      <CCol sm="12">
        <CCard>
          <CCardBody class="options-container">
            <CRow>
              <CCol sm="12" class="container-matching mb-3">
                <MatchingTable
                  :results="matchingValues"
                  :rows="getFilterRows"
                  :cols="getFilterCols"
                  modal
                />
                <table>
                  <thead>
                    <th colspan="2"></th>
                    <th v-for="filtro in getFiltros" :key="filtro.type">
                      {{ filtro.name }}
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan="2" class="bg-gris vertical-cell">
                        <p class="text-rotate">FILTER</p>
                      </td>
                      <td class="row-color">ROW</td>
                      <td v-for="filtro in getFiltros" :key="filtro.type">
                        <CInputCheckbox
                          custom
                          :disabled="isInRow(filtro.type) ||generated"
                          :checked="isFilterSelected('row', filtro.type)"
                          @update:checked="selectFilter($event, 'row', filtro)"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="col-color">COL</td>
                      <td v-for="filtro in getFiltros" :key="filtro.type">
                        <CInputCheckbox
                          custom
                          v-if="filtro.type != 'details'"
                          :disabled="isInCol(filtro.type) ||generated"
                          :checked="isFilterSelected('col', filtro.type)"
                          @update:checked="selectFilter($event, 'col', filtro)"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="2" class="bg-gris vertical-cell">
                        <p class="text-rotate">ORDER</p>
                      </td>
                      <td class="row-color">ROW</td>
                      <td colspan="5">
                        <Draggable
                          v-if="row.length > 0"
                          :list="row"
                          :animation="200"
                          :disabled="generated"
                          class="selected-list"
                        >
                          <div
                            v-for="item in row"
                            :key="item.type"
                            class="selected-item"
                          >
                            {{ item.name }}
                          </div>
                        </Draggable>
                      </td>
                    </tr>
                    <tr>
                      <td class="col-color">COL</td>
                      <td colspan="5">
                        <Draggable
                          v-if="col.length > 0"
                          :list="col"
                          :animation="200"
                          :disabled="generated"
                          class="selected-list"
                        >
                          <div
                            v-for="item in col"
                            :key="item.type"
                            class="selected-item"
                          >
                            {{ item.name }}
                          </div>
                        </Draggable>
                      </td>
                    </tr>
                    <tr v-for="rowItem in row" :key="rowItem.type">
                      <td colspan="2" class="row-color">{{ rowItem.name }}</td>
                      <td colspan="5" class="p-0 fill-height">
                        <div class="choice-list">
                          <div
                            class="choice-item"
                            v-for="(option, i) in getOptionsByType(
                              rowItem.type
                            )"
                            :key="i"
                          >
                            <CInputCheckbox
                              custom
                              :disabled="generated"
                              :label="getOptionName(rowItem.type, option)"
                              :checked="isSelectedOpt('row', rowItem, option)"
                              @update:checked="
                                toggleOption($event, 'row', rowItem, option)
                              "
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-for="colItem in col" :key="colItem.type">
                      <td colspan="2" class="col-color">{{ colItem.name }}</td>
                      <td colspan="5" class="p-0 fill-height">
                        <div class="choice-list">
                          <div
                            class="choice-item"
                            v-for="(option, i) in getOptionsByType(
                              colItem.type
                            )"
                            :key="i"
                          >
                            <CInputCheckbox
                              custom
                              :disabled="generated"
                              :label="getOptionName(colItem.type, option)"
                              :checked="isSelectedOpt('col', colItem, option)"
                              @update:checked="
                                toggleOption($event, 'col', colItem, option)
                              "
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="resetFilters"
      >
        <div>
          <CIcon name="cil-brush-alt"/>
          <span class="ml-1">{{$t('label.clearFilters')}}</span>
        </div>
      </CButton>
      <CButton
        shape="square"
        color="add"
        class="d-flex align-items-center"
        :disabled="isGeneratedDisabled"
        @click="addGenerated"
      >
        <div>
          <CIcon name="checkAlt" />
          <span class="ml-1">GENERAR</span>
        </div>
      </CButton>
      <CButton
        shape="square"
        color="wipe"
        @click.stop="toggle(false)"
      >
        <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import { mapState } from "vuex";
import Draggable from "vuedraggable";
import MatchingTable from "./matching-table";

//Data
function data() {
  return {
    loaded: false,
    modalActive: this.modal,
    // matching
    generated: false,
    row: [],
    col: [],
    bays: [],
    info: [],
    matchingValues: [],
    filtros: [
      {
        type: "status",
        name: "STATUS",
        alias: "STS",
        field: "TpCargoStatusId",
        values: [],
      },
      {
        type: "carrier",
        name: "CARRIER",
        alias: "CARRIER",
        field: "ShippingLineCode",
        values: [],
      },
      {
        type: "load",
        name: "LOAD PORT",
        alias: "POL",
        field: "LoadPortCode",
        values: [],
      },
      {
        type: "discharge",
        name: "DISCHARGE PORT",
        alias: "POD",
        field: "DischargePortCode",
        values: [],
      },
      {
        type: "details",
        name: "CONTAINER DETAILS",
        alias: "TYPE",
        field: "TpCargoDetailId",
        values: [],
      },
    ],
  };
}

//Methods
function toggle(newVal) {
  if (newVal) {
    this.getOptions();
  }

  this.modalActive = newVal;
  this.$nextTick(() => {
    this.resetValues();
  });
}
function resetValues() {
  this.info = [];
  this.row = [];
  this.col = [];
  this.bays = [];
  this.matchingValues = [];
  this.generated = false;

  this.filtros.forEach(element => {
    element.values = [];
  });
}
function resetFilters() {
  this.row = [];
  this.col = [];
  this.matchingValues = [];
  this.generated = false;

  this.filtros.forEach(element => {
    element.values = [];
  });
  this.$emit("generated", null);
}
function setValues() {
  if (!this.matchInfo) return;

  this.row = [...this.matchInfo.row];
  this.col = [...this.matchInfo.col];
  this.matchingValues = [...this.matchInfo.results];
}
function getOptions() {
  if (this.modulo == "stowage")
    this.$store.state.planificacionestiba.apiStateForm = 1;
  if (this.modulo == "visit") this.$store.state.visitas.apiStateForm = 1;

  let ruta =
    this.modulo == "stowage"
      ? "EdiCargoFilters-list"
      : "VisitCargoFilters-list";

  let parametros =
    this.modulo == "stowage"
      ? {
          EdiFileId: this.ediFileId,
        }
      : {
          VisitEdiFileId: this.visitEdiFileId,
        };

  this.$http
    .ejecutar("GET", ruta, parametros)
    .then((response) => {
      this.info = response.data.data[0];
      this.setValues();
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      if (this.modulo == "stowage")
        this.$store.state.planificacionestiba.apiStateForm = 0;
      if (this.modulo == "visit") this.$store.state.visitas.apiStateForm = 0;
    });
}
function selectFilter(event, type, filter) {
  if (event == true) {
    if (type == "row") {
      this.row.push({ ...filter });
      let found = this.col.findIndex((item) => item.type === filter.type);
      if (found != -1) {
        this.col.splice(found, 1);
      }
    } else if (type == "col") {
      this.col.push({ ...filter });
      let found = this.row.findIndex((item) => item.type === filter.type);
      if (found != -1) {
        this.row.splice(found, 1);
      }
    }
  } else {
    if (type == "row") {
      let index = this.row.findIndex((opt) => opt.type === filter.type);
      this.row.splice(index, 1);
    } else if (type == "col") {
      let index = this.col.findIndex((opt) => opt.type === filter.type);
      this.col.splice(index, 1);
    }
  }
}
function isFilterSelected(type, filter) {
  if (type == "row") {
    let found = this.row.find((item) => item.type == filter);
    if (!found) return false;
    else return true;
  }
  if (type == "col") {
    let found = this.col.find((item) => item.type == filter);
    if (!found) return false;
    else return true;
  }

  return false;
}
function isInRow(type) {
  if (type == "load") {
    let found = this.row.findIndex((item) => item.type == "discharge");
    return found != -1;
  }
  if (type == "discharge") {
    let found = this.row.findIndex((item) => item.type == "load");
    return found != -1;
  }

  return false;
}
function isInCol(type) {
  if (type == "load") {
    let found = this.col.findIndex((item) => item.type == "discharge");
    return found != -1;
  }
  if (type == "discharge") {
    let found = this.col.findIndex((item) => item.type == "load");
    return found != -1;
  }

  return false;
}
function getOptionsByType(type) {
  switch (type) {
    case "status":
      return this.info.StatusJson;
    case "carrier":
      return this.info.ShippingLineJson;
    case "load":
      return this.info.LoadPortJson;
    case "discharge":
      return this.info.DischargePortJson;
    case "details":
      return this.info.TpCargoDetailJson;
    default:
      return [];
  }
}
function getOptionName(type, option) {
  switch (type) {
    case "status":
      return option.Status;
    case "carrier":
      return option.ShippingLineCode;
    case "load":
      return option.LoadPortCode;
    case "discharge":
      return option.DischargePortCode;
    case "details":
      return option.TpCargoDetailCode;
    default:
      return "";
  }
}

function isSelectedOpt(location, filter, value) {
  if (location == "row") {
    let index = this.row.findIndex((item) => item.type == filter.type);
    let found = this.row[index].values.findIndex(
      (item) => item[filter.field] == value[filter.field]
    );

    return found != -1;
  }
  if (location == "col") {
    let index = this.col.findIndex((item) => item.type == filter.type);
    let found = this.col[index].values.findIndex(
      (item) => item[filter.field] == value[filter.field]
    );

    return found != -1;
  }
  return false;
}
function toggleOption(event, location, filter, value) {
  if (event) {
    if (location == "row") {
      let index = this.row.findIndex((item) => item.type == filter.type);
      if (filter.type == "load" || filter.type == "discharge") {
        this.row[index].values.splice(0, 1);
        this.row[index].values.push({ ...value });
      } else {
        this.row[index].values.push({ ...value });
      }
    }
    if (location == "col") {
      let index = this.col.findIndex((item) => item.type == filter.type);
      if (filter.type == "load" || filter.type == "discharge") {
        this.col[index].values.splice(0, 1);
        this.col[index].values.push({ ...value });
      } else {
        this.col[index].values.push({ ...value });
      }
    }
  } else {
    if (location == "row") {
      let index = this.row.findIndex((item) => item.type == filter.type);
      let found = this.row[index].values.findIndex(
        (item) => item[filter.field] == value[filter.field]
      );
      this.row[index].values.splice(found, 1);
    }
    if (location == "col") {
      let index = this.col.findIndex((item) => item.type == filter.type);
      let found = this.col[index].values.findIndex(
        (item) => item[filter.field] == value[filter.field]
      );
      this.col[index].values.splice(found, 1);
    }
  }
}

function addGenerated() {
  this.getMatchingValues();
}

function getMatchingValues() {
  if (this.row.length == 0 && this.col.length == 0) {
    this.$notify({
      group: "container",
      text: this.$t('label.needFilter'),
      type: "error",
    });
    return;
  }
  if (this.row.length == 0) {
    this.$notify({
      group: "container",
      text: this.$t('label.needOptionRow'),
      type: "error",
    });
    return;
  }
  if (this.col.length == 0) {
    this.$notify({
      group: "container",
      text: this.$t('label.needOptionCol'),
      type: "error",
    });
    return;
  }
  if (this.row.length != 0) {
    let filtros = this.row.filter((it) => it.values.length == 0).map((item) => item.name).join(', ');
    if(filtros) {
      this.$notify({
        group: "container",
        text: this.$t('label.needFilter')+' '+filtros+'.',
        type: "error",
      });
      return;
    }
  }
  if (this.col.length != 0) {
    let filtros = this.col.filter((it) => it.values.length == 0).map((item) => item.name).join(', ');
    if(filtros) {
      this.$notify({
        group: "container",
        text: this.$t('label.needFilter')+' '+filtros+'.',
        type: "error",
      });
      return;
    }
  }
  if (this.row.length != 0 && this.col.length != 0) {
    let filtrosRow = this.row.filter((it) => it.values.length == 0).map((item) => item.name);
    let filtrosCol = this.col.filter((it) => it.values.length == 0).map((item) => item.name);
    let totalFilters = filtrosRow.concat(filtrosCol);
    if(totalFilters.length != 0) {
      this.$notify({
        group: "container",
        text: this.$t('label.needFilter')+' '+totalFilters.join(', ')+'.',
        type: "error",
      });
      return;
    }
  }

  if (this.modulo == "stowage")
    this.$store.state.planificacionestiba.apiStateForm = 1;
  if (this.modulo == "visit") this.$store.state.visitas.apiStateForm = 1;

  let ruta = "visitFilterResultV2"; //VisitFilterResult

  let fileId = this.modulo == "stowage" ? this.ediFileId : this.visitEdiFileId;

  let FilterJson = {
    FilterJson: [
      {
        ColRowJson: [
          {
            col: this.getFilterCols,
            row: this.getFilterRows,
          },
        ],
      },
      {
        EdiFileId: fileId,
      },
    ],
  };

  this.$http
    .postJSON(ruta, FilterJson)
    .then((response) => {
      this.matchingValues = this.formatMatchingValues(this.formatResponse(response.data.data[0].ResultJson.FilterJson));
      this.generated = true;
      this.$emit("generated", {
        col: this.getFilterCols,
        row: this.getFilterRows,
        results: this.matchingValues,
        tables: this.formatedMatchingSubtotal(response.data.data[0].ResultJson.FilterJson),
      });
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      if (this.modulo == "stowage")
        this.$store.state.planificacionestiba.apiStateForm = 0;
      if (this.modulo == "visit") this.$store.state.visitas.apiStateForm = 0;
    });
}

// ******************** Matching Format ****************************
function splitMatching(arr) {
  let arregloSeparado = [];
  let size = 20;

  if(arr.length > 20){
    let status = this.getFilterRows.find((item) => item.type == 'status');
    // if(status && status.values.length == 2) size = arr.length > 20 ? arr.length / 2 : 20;

    if(status && status.values.length == 2) {
      let carrier = this.getFilterRows.find((item) => item.type == 'carrier');
      let carrierSize = carrier ? carrier.values.length : 1;
      let details = this.getFilterRows.find((item) => item.type == 'details');
      let detailsSize = details ? details.values.length : 1;

      size = arr.length > 20 ? arr.length / 2 : 20;

      if(carrier) {
        size = (carrierSize * detailsSize) > 20
          ? (carrierSize / 2) * detailsSize
          : 20;
      }
      if(!carrier && details) {
        size = detailsSize > 20
          ? (detailsSize / 2)
          : 20;
      }
    }
  
    if(!status || (status && status.values.length == 1)) {
      let carrier = this.getFilterRows.find((item) => item.type == 'carrier');
      let carrierSize = carrier ? carrier.values.length : 1;
      let details = this.getFilterRows.find((item) => item.type == 'details');
      let detailsSize = details ? details.values.length : 1;

      if(carrier) {
        size = (carrierSize * detailsSize) > 20
          ? (carrierSize / 2) * detailsSize
          : 20;
      }
      if(!carrier && details) {
        size = detailsSize > 20
          ? (detailsSize / 2)
          : 20;
      }
    }
  }

  for (var i = 0; i < arr.length; i += size) {
    arregloSeparado.push(arr.slice(i, i + size));
  }
  return arregloSeparado;
}
function formatResponse(arr) {
  if (arr.length == 0) return [];

  let newArr = [];
  let params = Object.keys(arr[0]);
  let values = Object.values(arr[0]);
  for (let index = 0; index < params.length; index++) {
    newArr.push({
      [params[index]]: values[index],
    });
  }
  return newArr;
}
function formatMatchingValues(arr) {
  if (arr.length == 0) return [];

  let newArr = [];
  for (let index = 0; index < arr.length; index++) {
    newArr.push(Object.values(arr[index])[0].map((item) => item.Value));
  }
  return newArr;
}
function formatMatchingLabels(arr) {
  if (arr.length == 0) return [];

  let newArr = [];
  for (let index = 0; index < arr.length; index++) {
    newArr.push(Object.values(arr[index])[0].map((item) => item.Rows.reduce(function(result, current) {
      return Object.assign(result, current);
    }, {})));
  }
  return newArr.flat().filter((v,i,a)=>a.findIndex(v2=>(JSON.stringify(v2) === JSON.stringify(v)))===i);
}
function selectedRowLabels(labels) {
	let baseFilters = [
		{
		  type: "status",
		  name: "STATUS",
		  alias: "STS",
		  field: "Status",
		  values: [],
		},
		{
		  type: "carrier",
		  name: "CARRIER",
		  alias: "CARRIER",
		  field: "ShippingLineCode",
		  values: [],
		},
		{
		  type: "load",
		  name: "LOAD PORT",
		  alias: "POL",
		  field: "LoadPortCode",
		  values: [],
		},
		{
		  type: "discharge",
		  name: "DISCHARGE PORT",
		  alias: "POD",
		  field: "DischargePortCode",
		  values: [],
		},
		{
		  type: "details",
		  name: "CONTAINER DETAILS",
		  alias: "TYPE",
		  field: "TpCargoDetailCode",
		  values: [],
		},
	  ];

	for (let index = 0; index < labels.length; index++) {
		let keys = Object.keys(labels[index]);
		for (let j = 0; j < keys.length; j++) {
			let found = baseFilters.findIndex((item) => item.field == keys[j]);
			if(found != -1){
				let foundFilter = baseFilters[found].values.findIndex((item) => item[keys[j]] == labels[index][keys[j]]);
				if(foundFilter == -1) {
					baseFilters[found].values.push({
						[keys[j]]: labels[index][keys[j]],
					});
				}
			}
		}	
	}

	for (let index = 0; index < baseFilters.length; index++) {
		let foundFil = this.row.findIndex((item) => baseFilters[index].type ==item.type);
		if(foundFil != -1) {
			baseFilters[index].values = this.row[foundFil].values.filter((el) => {
				return baseFilters[index].values.some((f) => {
					return f[baseFilters[index].field] === el[baseFilters[index].field];
				});
			});
		}
	}

	return baseFilters.filter((item) => item.values.length != 0);
}
function formatedMatchingSubtotal(response) {
	let formatedResponse = this.formatResponse(response);
	let formatedValues = this.formatMatchingValues(formatedResponse),
		formatedLabels = this.formatMatchingLabels(formatedResponse);
	let splitValues = this.splitMatching(formatedValues),
		splitLabels = this.splitMatching(formatedLabels);
	let matching = [];
	for (let index = 0; index < splitValues.length; index++) {
		matching.push({
			results: splitValues[index],
			labels: this.selectedRowLabels(splitLabels[index])
		});	
	}

	return matching;
}

// Computeds
function getFiltros() {
  return [...this.filtros];
}
function getFilterRows() {
  return this.row.filter((item) => item.values.length != 0);
}
function getFilterCols() {
  return this.col.filter((item) => item.values.length != 0);
}
function isGeneratedDisabled(){
  if(this.row.length == 0 && this.col.length == 0)
    return true;

  for (let index = 0; index < this.row.length; index++) {
    if(this.row[index].values.length != 0)
      return false;
  }
  for (let index = 0; index < this.col.length; index++) {
    if(this.col[index].values.length != 0)
      return false;
  }

  return true;
}

export default {
  name: "matching-modal",
  props: {
    modal: Boolean,
    matchInfo: {
      type: Object,
      default: null,
    },
    modulo: {
      type: String,
      default: "stowage",
    },
    visitEdiFileId: {
      type: String,
      default: "",
    },
  },
  components: {
    Draggable,
    MatchingTable,
  },
  data,
  methods: {
    toggle,
    getOptions,
    resetValues,
    selectFilter,
    isFilterSelected,
    isInRow,
    isInCol,
    getOptionsByType,
    getOptionName,
    toggleOption,
    isSelectedOpt,
    addGenerated,
    setValues,
    getMatchingValues,
    resetFilters,
    formatMatchingLabels,
    formatMatchingValues,
    formatResponse,
    formatedMatchingSubtotal,
    splitMatching,
    selectedRowLabels,
  },
  computed: {
    getFiltros,
    getFilterRows,
    getFilterCols,
    isGeneratedDisabled,
    ...mapState({
      ediFileId: (state) => state.planificacionestiba.EdiFileId,
      ediInfo: (state) => state.planificacionestiba.EdiFileInfo,
    }),
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>

<style scoped>
/* Tabla Filtros */
.text-rotate {
  transform: rotate(-90deg);
  color: #3c4b64;
  margin-top: 22px;
}
.vertical-cell {
  padding: 0;
  height: 70px;
}

.result-table {
  width: 60%;
  height: 100%;
  border: 1px solid #000;
  /* background-color: lightskyblue; */
}
.container-matching {
  width: 100%;
  display: flex;
}

table {
  border-collapse: collapse;
  /* border-style: hidden; */
  width: 40%;
  height: 10%;
  text-align: center;
  min-height: 1px;
}
table,
table th,
table td {
  border: 1px solid #000;
}

table th {
  background-color: #e0e0e0;
}
table th,
table td {
  padding: 4px 5px;
}

.fill-height {
  height: 100%;
}

.selected-list {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
}
.selected-item {
  width: calc(calc(100% / 5) - 4px);
  height: 100%;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: move;
  font-size: 0.6rem;
  background-color: #e8e9ed;
}

.choice-list {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin !important;
  max-width: 356.3px;
}
.choice-item {
  width: calc(calc(100% / 5) + 10px);
  min-width: calc(calc(100% / 5) + 10px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-top: none;
  border-bottom: none;
  border-left: none;
  padding: 10px 5px;
}

.bg-gris {
  background-color: #e0e0e0;
}
.row-color {
  background-color: #5d208a;
  color: #fff;
}
.col-color {
  background-color: #88a9de;
  color: #fff;
}
::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background: #aaa;
}

::-webkit-scrollbar-thumb {
    background: #393812;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: #000;
}
</style>