<template>
<CModalExtended
  :title="$t('label.legend')"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
  <CRow>
    <CCol sm="12" class="max-container">
      <div class="legend-container" v-if="currentItems.length != 0">
        <div
          v-for="(item, index) in currentItems"
          :key="item.Name+''+index"
          class="legend-wrapper"
        >
          <div class="legend-icon">
            <div
              class="bg-position"
              :style="{ backgroundColor: colorPreference(item) }"
              v-if="hasColor(item)"
            ></div>

            <div :class="item.Class || null" v-if="!isFromPreference(item)"></div>
            <div :class="slugName(item.Name)" v-if="isFromPreference(item)"></div>

            <!-- Imprimir Letra -->
             <img
                :src="getLetter(item)"
                alt=""
                class="center-icon"
                v-if="hasLetter(item)"
              >
          </div>
          <p
            class="legend-text"
            :class="{ 'ml-5': checkIsOverSizeRight(item) }"
          >
            {{ hasLetter(item) ? getLetterName(item) : item.Name }}
          </p>
        </div>
      </div>
    </CCol>
  </CRow>         
  <template #footer>
    <CButton
      shape="square"
      color="wipe"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/>
      <span class="ml-1">{{ $t('button.exit') }}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import { mapState } from "vuex";
import { GenerateSvgPlaceholder, slugify } from '@/_helpers/funciones';
import ENUM from '@/_store/enum';

//Data
function data() {
  return {
    loaded: false,
    modalActive: this.modal,
    items: [],
    loadDefault: [
      {
        Name: 'OCUPADO',
        Color: '#ec631d',
        FgColor: true,
      },
      {
        Name: 'OCUPADO POR OTROS PUERTOS',
        Color: '#aaaaaa',
        FgColor: true,
      },
      {
        Name: 'PROHIBIDO ASIGNAR',
        Class: 'invalid-position',
        FgColor: false,
      }
    ],
  }
}

//Methods
function toggle(newVal) {
  if(newVal)
    if(this.loadView)
      this.getLegends();
    else 
      this.items = [...this.leyenda, ...this.preferencias];
  else
    this.items = [];

  this.modalActive = newVal;
}
function getLegends() {
  if(this.visit)
    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  else
    this.$store.state.planificacionestiba.apiStateForm = 1;

  let whiteList = [
    "878E5685-0BC1-43E1-9490-F3A1F2645630",
    "6448779A-474A-4BDA-834B-7F069375FDA8"
  ];

  let ruta = '';
  if(this.visit)
    ruta = 'VisitEdiMandatory-list';
  else
    ruta = this.loadView ? 'EdiMandatoryLoad-list' : 'EdiMandatory-list';

  let params = null;
  if(this.visit)
    params = {
      VisitId: this.VisitId,
      VisitEdiFileId: this.EdiFileId,
      VesselBayId: this.bayId,
      EdiViewId: '',
    };
  else
    params = {
      StowagePlanningId: this.planificacionId,
      EdiFileId: this.ediFileId,
      VesselBayId: this.bayId,
      EdiViewId: '',
    };

  this.$http.ejecutar("GET", ruta,params)
    .then((response) => {
      this.items = [...this.loadDefault, ...response.data.data, ...this.preferencias];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      if(this.visit)
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
      else
        this.$store.state.planificacionestiba.apiStateForm = 0;
    });
}

function hasColor(item) {
  return item.Color && item.FgColor;
}
function isFromPreference(item) {
  return item.Class ? false : true;
}
function slugName(name) {
  return slugify(name);
}
function colorPreference(item) {
  return this.hasColor(item) ? item.Color : null;
}
function checkIsOverSizeRight(item) {
  return item.Class == "oversize-right" ? true : null;
}

function hasLetter(item) {
  return item.Class && item.Class == 'letter';
}
function getLetter(item) {
  if(!this.hasLetter(item))
    return '';

  let index = item.Name.indexOf("(");
  if(index == -1)
    return '';

  return GenerateSvgPlaceholder({
    width: 40,
    height: 40,
    bgColor: 'transparent',
    textColor: '#3c4b64',
    fontSize: 30,
    fontWeight: '400',
    text: `${item.Name.slice(index+1, index+2)}`
  });
}
function getLetterName(item) {
  if(!this.hasLetter(item))
    return '';

  let index = item.Name.indexOf("(");
  if(index == -1)
    return '';

  return item.Name.slice(0, index-1);
}

//Computed
function currentItems() {
  if(!this.visit) return this.items;

  if(this.listType == 1) {
    this.items.push({
      "Id":"",
      "Name":`${this.$t("label.previousPosition")} (P)`,
      "Class":"letter"
    });
  } else if(this.visitType == 'discharge') {
    this.items.push({
      "Id":"",
      "Name":`${this.$t("label.discharge")} (D)`,
      "Class":"letter"
    });
  } else if(this.visitType == 'load') {
    this.items.push({
      "Id":"",
      "Name":`${this.$t("label.loadPortSituation")} (L)`,
      "Class":"letter"
    });
  }

  return this.items;
}

export default {
  name: 'leyenda-modal',
  props: {
    modal: Boolean,
    preferencias: {
      type: Array,
      default: () => [],
    },
    leyenda: {
      type: Array,
      default: () => [],
    },
    loadView: {
      type: Boolean,
      default: false,
    },
    bayId: {
      type: String,
      default: '',
    },
    modulo: {
      type: String,
      default: 'stowage'
    },
    // Visit
    visit: {
      type: Boolean,
      default: false,
    },
    visitType: {
      type: String,
      default: ''
    },
    listType: {
      type: Number,
      default: 0
    },
  },
  data,
  methods: {
    toggle,
    hasColor,
    isFromPreference,
    slugName,
    colorPreference,
    checkIsOverSizeRight,
    hasLetter,
    getLetter,
    getLetterName,
    getLegends,
  },
  computed: {
    currentItems,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      ediFileId: (state) => state.planificacionestiba.EdiFileId,
      VisitId: state => state.visitas.VisitId,
      EdiFileId: state => state.visitas.EdiFileId,
    }),
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>

<style scoped>
/* Contenedor y cuadros */
.max-container {
  height: 390px;
  max-height: 390px;
  overflow: auto;
  scrollbar-width: thin;
}

.legend-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.legend-wrapper {
  margin: 5px;
  width: calc(calc(100% / 3) - 10px);
  display: flex;
  align-items: center;

  padding: 10px;
  /* border: 1px solid #000; */
}
.legend-icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border: 1px solid #000;
  position: relative;
}
.legend-text {
  margin-left: 10px;
  margin-top: 10px;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
}
.bg-position {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: transparent;
  transition: background-color .5s ease-in-out;
}

/* Iconos */
.flat-rack,
.ft-flat-rack {
  width: 35%;
  height: 35%;
  border: 1px solid #555;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 0;
  left: 0;
}
.plataforma,
.pl-plataforma {
  width: 35%;
  height: 35%;
  border: 1px solid #555;
  border-bottom: none;
  border-right: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
.tanque,
.tk-tanque {
  width: 25%;
  height: 25%;
  border: 1px solid #555;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin: 0 0 2px 2px;
}
.open-top,
.ot-open-top {
  position: absolute;
  width: 100%;
  height: 30%;
  border: 1px solid #555;
  border-top: none;
  border-right: none;
  border-left: none;
  top: 0;
}
.standard,
.st-estandar {
  background-image: url('/img/iconos/Standard.svg');
  position: absolute;
  width: 40%;
  height: 40%;
  top: 0;
  left: 0;
  margin-top: 2px;
  margin-left: 2px;
}

.imdg {
  position: absolute;
  width: 55%;
  height: 55%;
  top: 50%;
  left: 50%;
  border: 1px solid red;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  -o-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.high-cube,
.hc-high-cube {
  width: 40%;
  height: 40%;
  border: 1px solid #555;
  -moz-border-radius: 0 0 100% 0;
  -webkit-border-radius: 0 0 100% 0;
  border-radius: 0 0 100% 0;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 0;
  left: 0;
}
.high-cube-45,
.footer-45 {
  width: 40%;
  height: 40%;
  border: 1px solid #555;
  -moz-border-radius: 0 0 100% 0;
  -webkit-border-radius: 0 0 100% 0;
  border-radius: 0 0 100% 0;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #373737;
}
.reefer-high-cube,
.refrigerado-high-cube,
.rt-refrigerado-high-cube  {
  background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
  background-position: left top, right bottom;
  background-size: 40% 40%, 40% 40%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height:100%;
}
.reefer {
  width: 40%;
  height: 40%;
  border: 1px solid #555;
  -moz-border-radius: 0 100% 0 0;
  -webkit-border-radius: 0 100% 0 0;
  border-radius: 100% 0 0 0;
  border-bottom: none;
  border-right: none;
  position: absolute;
  right: 0;
  bottom: 0;
}
.oversize-top {
  width: 100%;
  height: 50%;
  border: 1px solid #555;
  background: #555;
  -moz-border-radius: 100px 100px 0 0;
  -webkit-border-radius: 100px 100px 0 0;
  border-radius: 100px 100px 0 0;
  position: absolute;
  top: -50%;
  z-index: 100;
}
.oversize-right {
  width: 50%;
  height: 100%;
  border: 1px solid #555;
  background: #555;
  border-radius: 0 100px 100px 0;
  position: absolute;
  right: -50%;
  z-index: 1;
}
.oversize-left {
  width: 50%;
  height: 100%;
  border: 1px solid #555;
  background: #555;
  border-radius: 100px 0 0 100px;
  position: absolute;
  left: -50%;
  z-index: 1;
}
.oversize-back {
  width: 100%; 
  height: 20%; 
  border: 1px solid #555;
  background-color: #555;
  border-bottom: none;
  border-right: none;
  position: absolute;
  bottom: 0;
}
.oversize-front {
  width: 100%; 
  height: 20%; 
  border: 1px solid #555;
  background-color: #555;
  border-bottom: none;
  border-right: none;
  position: absolute;
  top: 0;
}

.empty {
  background-image: url('/img/iconos/Empty.svg');
  position: absolute;
  width: 25%;
  height: 25%;
  top: 4%;
  left: 75%;
}
.hazard-available {
  background-image: url('/img/iconos/AvailableHazard.svg');
  position: absolute;
  width: 35%;
  height: 35%;
  top: 0;
  right: 0;
}
.hazard-restriction {
  background-image: url('/img/iconos/HazardRestriction.svg');
  position: absolute;
  width: 35%;
  height: 35%;
  top: 0;
  right: 0;
}
.reefer-plug {
  background-image: url('/img/iconos/ReeferPlug.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.occupied-by-40,
.occupied-by-45 {
  background-image: url('/img/iconos/OccupiedBy40.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.occupied-by-45 {
  background-image: url('/img/iconos/Plus45_1.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.occupied-oversize,
.reserved {
  background-image: url('/img/iconos/OccupiedOversize.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.restow {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #555;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 0;
}

.center-icon { /* Center a .svg */
  position: absolute;
  width: 45%;
  height: 45%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.invalid-position {
  background-image: url('/img/iconos/ban-solid.svg');
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
  position: absolute;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -moz-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  -o-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}
</style>