<template>
<CModalExtended
  :title="$t('label.print')"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
  <CRow class="options">
    <CCol
      sm="12"
    >
      <CCard>
        <CCardBody class="options-container">
          <CRow>
            <CCol
              sm="12"
              class="checkbox-row"
            >
              <CInputCheckbox
                custom
                :checked="isSelectedAll"
                :label="$t('label.selectAll')"
                class="float-left"
                @update:checked="selectAll($event)"
              />
            </CCol>
            <CCol sm="12">
              <hr class="mt-1 mb-1">
            </CCol>
            <CCol sm="12">
              <Draggable
                v-if="items.length > 0"
                :list="items"
                :animation="200"
                class="row"
              >
                <CCol
                  v-for="option in formatedOptions"
                  :key="option.EdiViewId"
                  sm="12"
                  class="checkbox-row items-cursor"
                  :class="{ 'checked-option': option.selected ? true : null }"
                >
                  <CInputCheckbox
                    custom
                    :checked="option.selected"
                    :label="getOptionName(option)"
                    class="float-left"
                    addInputClasses="loadplan-checkbox-cursor"
                    addLabelClasses="loadplan-items-cursor"
                    @update:checked="selectOption($event, option)"
                  />
                </CCol>
              </Draggable>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>         
  <template #footer>
    <CButton
      shape="square"
      color="add"
      class="d-flex align-items-center"
      :disabled="selectedOptions.length == 0"
      @click="printPdf"
    >
      <div>
        <CIcon name="checkAlt"/>
        <span class="ml-1">{{ $t('button.accept') }}</span>
      </div>
    </CButton>
    <CButton
      shape="square"
      color="wipe"
      @click.stop="toggle(false)"
    >
      <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import Draggable from 'vuedraggable';
import ldb from 'localdata';

//Data
function data() {
  return {
    loaded: false,
    modalActive: this.modal,
    items: [],
    selectedOptions: [],
  }
}

//Methods
function printPdf() {
  let orderedOptions = this.items.filter((item) => {
    return this.selectedOptions.includes(item);
  });

  let pageInfo = {
    ...this.extraInfo,
    viewsInfo: orderedOptions,
    type: 'load-plan',
  };

  ldb.set('pageInfo', JSON.stringify(pageInfo));
  // localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

  let routeData = this.$router.resolve({name: 'pdfPage'});
  window.open(routeData.href, '_blank');

  this.$emit('printing');
  this.modalActive = false;
}
function toggle(newVal) {
  if(newVal){
    this.items = [...this.viewsInfo];
    this.selectedOptions = [...this.viewsInfo];
  }else{
    this.items = [];
    this.selectedOptions = [];
  }

  this.modalActive = newVal;
}

function selectOption(event, option){
  if(event ==  true){
    this.selectedOptions.push(option);
  } else {
    let index = this.selectedOptions.findIndex((opt) => opt.EdiViewId === option.EdiViewId);
    this.selectedOptions.splice(index, 1);
  }
}
function getOptionName(option) {
  let filtro = '';
  if(Object.keys(option.filter).length != 0)
    filtro = ` - ${this.$t("label.filteredBy")} ${option.filter.PortCode}`;

  return `${option.EdiViewName}${filtro}`;
}

function selectAll(event) {
  if(event) {
    this.items.forEach(element => {
      let index = this.selectedOptions.findIndex((item) => item.EdiViewId === element.EdiViewId);
      if(index == -1)
        this.selectedOptions.push(element);
    });
  } else {
    this.selectedOptions.splice(0, this.selectedOptions.length);
  }
}

// Computeds
function isSelectedAll() {
  return this.items.length == this.selectedOptions.length;
}
function formatedOptions() {
  return this.items.map((item) => {
    let opt = this.selectedOptions.find((option) => option.EdiViewId === item.EdiViewId);
    
   return Object.assign({}, item, {
     selected: opt ? true : false,
   });
  });
}

export default {
  name: 'imprimir-modal',
  props: {
    modal: Boolean,
    viewsInfo: {
      type: Array,
      default: () => []
    },
    extraInfo: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    Draggable,
  },
  data,
  methods: {
    toggle,
    selectOption,
    printPdf,
    selectAll,
    getOptionName,
  },
  computed: {
    isSelectedAll,
    formatedOptions,
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>

<style scoped>
.options-container {
  padding-top: 5px;
  padding-bottom: 5px;

  margin: 0 5px;
  overflow: hidden;
}

.checkbox-row {
  padding-top: 5px;
  padding-bottom: 5px;
}
.checked-option {
  background-color: lightblue;
}
.options {
  min-height: 340px;
  max-height: 340px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.items-cursor {
  cursor: move;
}
.checkbox-cursor {
  cursor: pointer;
}
</style>