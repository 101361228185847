var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matching-results"},[_c('table',{staticClass:"match-results",style:({ transform: `scale(${_vm.getCurrentScale})` })},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2","rowspan":"2"}}),_c('th',{staticStyle:{"width":"100%"},attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.colLabels))])]),_c('tr',[_c('th',{staticClass:"bg-matching-white p-0",staticStyle:{"width":"80%"}},_vm._l((_vm.formatColLabels),function(colum,index){return _c('div',{key:index + 'collabel',staticClass:"columns-values"},_vm._l((colum.values),function(value,i){return _c('div',{key:i + 'collabelval',staticClass:"cell-box",style:({
                width: `calc( calc(100% / ${colum.values.length}))`,
                backgroundColor: value.Color ? `${value.Color}` : '#fff',
                color: value.Color ? '#fff' : '#000',
              })},[_vm._v(" "+_vm._s(_vm.getOptionName(colum.type, value))+" ")])}),0)}),0),_c('th',{staticClass:"bg-matching-white",attrs:{"colspan":"2"}},[_vm._v("TOTAL")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"bg-matching-grey p-0 text-rotate-labels"},[_c('span',[_vm._v(_vm._s(_vm.rowLabels))])]),_c('td',{staticClass:"p-0",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"rows-values",staticStyle:{"width":"100%","height":"100%"}},_vm._l((_vm.formatRowLabels),function(fila,index){return _c('div',{key:index + 'rowlabel',staticClass:"row-columns"},_vm._l((fila.values),function(value,i){return _c('div',{key:i + 'rowlabelval',staticClass:"cell-box row-values-padding",style:({
                  height: `calc( calc(100% / ${fila.values.length}))`,
                  backgroundColor: value.Color ? `${value.Color}` : '#fff',
                  color: value.Color ? '#fff' : '#000',
                })},[_vm._v(" "+_vm._s(_vm.getOptionName(fila.type, value))+" ")])}),0)}),0)]),_c('td',{staticClass:"p-0",staticStyle:{"height":"100%"}},_vm._l((_vm.formatMatchingValues),function(values,index){return _c('div',{key:index + 'col',staticClass:"columns-values",style:({ 
                height: `calc( calc(100% / ${_vm.formatMatchingValues.length}))`,
               })},_vm._l((values),function(value,i){return _c('div',{key:i + 'colval',staticClass:"cell-box",style:({ 
                width: `calc( calc(100% / ${_vm.getColNum}))`,
               })},[_vm._v(" "+_vm._s(value)+" ")])}),0)}),0),_c('td',{staticClass:"p-0",staticStyle:{"height":"100%"},attrs:{"colspan":"2"}},[_c('div',{staticClass:"matching-totales-vertical",staticStyle:{"height":"100%"}},_vm._l((_vm.rowTotales),function(total,index){return _c('div',{key:index + 'totalrow',staticClass:"cell-box",style:({ 
                height: `calc( calc(100% / ${_vm.rowTotales.length}))`,
               })},[_vm._v(" "+_vm._s(total)+" ")])}),0)])]),(_vm.lastTable)?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("TOTAL")]),_c('td',{staticClass:"p-0"},[_c('div',{staticClass:"columns-values"},_vm._l((_vm.colTotales),function(total,index){return _c('div',{key:index + 'totalcol',staticClass:"cell-box",style:({ width: `calc( calc(100% / ${_vm.getColNum}))` })},[_vm._v(" "+_vm._s(total)+" ")])}),0)]),_c('td',[_vm._v(_vm._s(_vm.getSumTotal))])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }